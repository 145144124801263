//_desktop.scss

@media (min-width: 1024px) {

  @media (max-width: 1200px) {
    header {
      text-align: left;
      padding: 1em 1.5em;
    }
  }

  .wrapper {
    position: relative;
    padding: 3em 0 5em;
    &.space {
      padding-top: 50vh;
    }
    &.space-bottom {
      padding-bottom: 50vh;
    }
  }
  .bottom.wrapper {
    padding: 0;
  }
  section.inner {
    max-width: 980px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row dense;
    grid-gap: 2em;
  }
  .dark.wrapper section.inner {
    align-items: center;
    padding-top: 4em;
  }

  .col5-10 {
    grid-column: 5/10;
  }
  .col-center {
    grid-column: 3/8;
  }

  .lang-menu {
    margin: 0;
    position: absolute;
    top: 1em;
    right: 1em;
    a {
      padding: 0 .75em;
    }
  }

  #bienfaits, .bienfaits {
    margin-top: 16em;
  }
  figure {
    grid-column: span 2;
    grid-row: span 1;
  }

  // grid spans
  .span2 {
    grid-column: span 2;
  }
  .span3 {
    grid-column: span 3;
  }
  .span4 {
    grid-column: span 4;
  }
  .span5 {
    grid-column: span 5;
  }
  .span6 {
    grid-column: span 6;
  }
  .span7 {
    grid-column: span 7;
  }
  .span8 {
    grid-column: span 8;
  }
  .span9, .full {
    grid-column: span 9;
  }

  // grid vertical aligns
  .grid-start {
    align-self: start;
  }
  .grid-end {
    align-self: end;
  }

  // grid loners
  .left {
    grid-column: 2/6;
  }
  .right {
    grid-column: 6/8;
  }

  header {
    display: block;
  }

  .fond-accueil {
    background-image: url("../img/accueil-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url('../img/accueil-fond-1600.jpg');
  }
  .fond-quoi {
    background-image: url("../img/quoi-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(100,77,55, 0.7) 60%, #c3e350), url('../img/quoi-fond-1600.jpg');
  }
  .fond-activites {
    background-image: url("../img/activites-fond-1600.jpg");
    // background-image: linear-gradient(to bottom, $green, rgba($green,.2) 50%, #fff), url('../img/activites-fond-1600.jpg');
    background-image: linear-gradient(to bottom, $green, transparent), url('../img/activites-fond-1600.jpg');
  }

  #logo-small {
    position: fixed;
    z-index: 1000;
    bottom: -210px;
    left: 50%;
    display: block;
    width: 200px;
    height: 200px;
    max-height: 25vh;
    margin: 0;
    margin-left: -100px;
    background: transparent url("../img/bestia-logo.svg") bottom center no-repeat;
    background-size:contain;
    transition: bottom .5s ease;
    &.active {
      bottom: -20px;
    }
    span {
      display: none;
    }
  }

  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }

}

@media (min-width: 1400px) {

  section.inner {
    max-width: 1200px;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }

}
