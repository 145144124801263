// _mobile.scss

// figure {
//   margin: 0;
// }
// figure img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

.wrapper {
  position: relative;
  padding: 0 0 1em;
  &.space {
    padding-top: 50vh;
  }
}
section.inner {
  max-width: 100%;
  margin: 0;
  padding: 1em;
}

@media (max-width: 479px) {

  header {
    position: fixed;
    left:-100%;
    height: 100%;
    width: 100%;
    padding: 2em;
    transition: left .5s ease;
    nav {
      overflow-y: auto;
      a {
        display: block;
        padding: 1em 1em .25em;
        margin: 0;
        font-size: 1.2em;
      }
    }
    &.active {
      left:0;
    }
  }
  #toggle-menu {
    position: fixed;
    z-index: 200;
    top: 1em;
    right: 1em;
    display: block;
    width: 3em;
    height: 3em;
    background: transparent url("../img/hamburger.svg") top left no-repeat;
    background-size: cover;
  }

  span.anchor {
    /* correct autoscroll position */
    position: absolute;
    top: -1em;
    main > & {
      top: 0em;
    }
  }

  h1 {
    line-height: 1.3;
    margin-bottom: .5em;
  }
  .span2, .span3, .span4, .span5, .span6, .span7, .span8, .span9, .full {
    margin: 1em 0;
  }

  .block {
    border-radius: 1em;
    padding: 1.5em 1em;
    p {
      margin-bottom: .75em;
    }
  }

  // .hero.logo h1 {
  //   top: 30vh;
  // }
  #balade-illus {
    bottom: -7vh;
    h2 {
      bottom: 3em;
    }
  }

}

@media (max-height: 479px) {
  .fond-accueil {
    position: absolute;
    top: 0;
  }
  .hero.logo {
    height: auto;
    h1 {
      position: relative;
      top: 0 !important;
      margin-top: 0;
      height: 30vh;
      img {
        width: 60vh;
      }
    }
  }
  #balade-illus {
    position: relative;
    bottom: auto;
    h2 {
      bottom: 4em;
    }
  }
}
