// _tablet.scss

@media (min-width: 480px) AND (max-width:1023px) {

  header {
    position: fixed;
    left:-100%;
    height: 100%;
    width: 100%;
    padding: 2em;
    transition: left .5s ease;
    nav {
      overflow-y: auto;
      a {
        display: block;
        padding: 1em 1em .25em;
        margin: 0;
        font-size: 1.2em;
      }
    }
    &.active {
      left:0;
    }
  }
  #toggle-menu {
    position: fixed;
    z-index: 200;
    top: 1em;
    right: 1em;
    display: block;
    width: 3em;
    height: 3em;
    background: transparent url("../img/hamburger.svg") top left no-repeat;
    background-size: cover;
  }

  .wrapper {
    position: relative;
    padding: 2em 0 4em;
    &.space {
      padding-top: 50vh;
    }
  }
  section.inner {
    max-width: 100%;
    margin: 0;
    padding: 2em;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row dense;
    grid-gap: 2em;
    & > * {
      grid-column: span 3;
    }
    .span3 {
      grid-column: span 2;
    }
    .contact & .span3 {
      grid-column: span 3;
    }
    .full {
      grid-column: span 6;
    }
  }
  .dark.wrapper section.inner {
    align-items: center;
    padding-top: 4em;
  }
  #balade-illus {
    bottom: -5vh;
    h2 {
      bottom: 2em;
    }
  }
  .fond-accueil {
    background-image: url("../img/accueil-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url('../img/accueil-fond-1600.jpg');
  }
  .fond-quoi {
    background-image: url("../img/quoi-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(100,77,55, 0.7) 60%, #c3e350), url('../img/quoi-fond-1600.jpg');
  }
  .fond-activites {
    background-image: url("../img/activites-fond-1600.jpg");
    background-image: linear-gradient(to bottom, $green, rgba($green,.2) 50%, #fff), url('../img/activites-fond-1600.jpg');
  }

  .fond-accueil, .fond {
    background-size: cover;
  }

  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }

}

// horizontal
@media (max-height: 700px) {
  #balade-illus {
    bottom: -10vh;
    h2 {
      bottom: 3em;
    }
  }
  .fond-accueil {
    background-image: url("../img/accueil-fond-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url('../img/accueil-fond-1024.jpg');
  }
  .fond-quoi {
    background-image: url("../img/quoi-fond-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(100,77,55, 0.7) 60%, #c3e350), url('../img/quoi-fond-1024.jpg');
  }
  .fond-activites {
    background-image: url("../img/activites-fond-1024.jpg");
    // background-image: linear-gradient(to bottom, $green, rgba($green,.2) 50%, #fff), url('../img/activites-fond-1024.jpg');
    background-image: linear-gradient(to bottom, $green, transparent), url('../img/activites-fond-1024.jpg');
  }
  .fond-accueil, .fond {
    background-size: cover;
  }
}

// portrait
@media (orientation: portrait) {
  .fond-accueil {
    background-image: url("../img/accueil-fondportrait-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url('../img/accueil-fondportrait-1024.jpg');
  }
  .fond-quoi {
    background-image: url("../img/quoi-fondportrait-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(100,77,55, 0.7) 60%, #c3e350), url('../img/quoi-fondportrait-1024.jpg');
  }
  .fond-activites {
    background-image: url("../img/activites-fondportrait-1024.jpg");
    background-image: linear-gradient(to bottom, $green, rgba($green,.2) 50%, #fff), url('../img/activites-fondportrait-1024.jpg');
  }
  .fond-accueil, .fond {
    background-size: cover;
  }
  @media (max-height: 800px) {
    .fond-accueil {
      background-image: url("../img/accueil-fondportrait-800.jpg");
      background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url('../img/accueil-fondportrait-800.jpg');
    }
    .fond-quoi {
      background-image: url("../img/quoi-fondportrait-800.jpg");
      background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(100,77,55, 0.7) 60%, #c3e350), url('../img/quoi-fondportrait-800.jpg');
    }
    .fond-activites {
      background-image: url("../img/activites-fondportrait-800.jpg");
      background-image: linear-gradient(to bottom, $green, rgba($green,.2) 50%, #fff), url('../img/activites-fondportrait-800.jpg');
    }
    .fond-accueil, .fond {
      background-size: cover;
    }
  }
}
