// _style.scss

// vh suppprt for mobiles with css var --vh updated by js: let vh = window.innerHeight * 0.01; document.documentElement.style.setProperty('--vh', `${vh}px`);
// @mixin vh($property, $value) {
//   #{$property}: $value * 1vh;
//   #{$property}: var(--vh, 1vh) * $value;
// }

/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
body {
  /* font-family: 'Quicksand', sans-serif; */
  /* font-family: 'Abhaya Libre', serif; */
  font-family: 'Libre Baskerville', serif;
  line-height: 1.5;
  background-color: $light;
  color: $light-dark;
}

h1, h2, h3, h4, h5 {
  line-height: 1.3;
  margin-bottom: .7em;
}
h1 {
  font-size: 2.2em;
  color: $light-dark;
  margin-top: 0.3em;
  margin-bottom: 1em;
}
h2 {
  font-size: 1.5em;
  color: $dark;
}
h2 + img {
  margin-top: .5em;
}
h3 {
  font-size: 1.2em;
  color: $dark;
  margin-top: 1em;
}
p {
  margin-bottom: 1em;
}
p:last-child {
  margin: 0;
}
img:last-child {
  margin-bottom: 0;
}
ul {
  position: relative;
  margin-top: 1em;
  line-height: 1.1;
  color: $dark;
}
li {
  margin: 0 0 1em 3em;
  span {
    position: absolute;
    left: 0;
    margin-top: -.25em;
  }
}
.badge {
  display: inline-block;
  background-color: $dark;
  color: $light;
  padding: .25em .5em;
  border-radius: .5em;
  margin-right: .25em;
}

a {
  color: $dark;
  &:hover, &:active {
    color: darken($yellow, 7%);
  }
  .green &:hover, &:active {
    color: $dark-green;
  }
}
button, input[type=button] {
  background-color: $yellow;
  color: $dark;
  border-radius: 2em;
  padding: 1.5em 2em;
  margin: 2em 0 1em;
  &:hover, &:active {
    background-color: darken($yellow, 2%);
  }
  cursor: pointer;
}
input[type=image] {
  margin: 2em;
  width:100px;
  height: auto;
}
form {
  display: flex;
  align-items: center;
  button {
    position: relative;
  }
}

header {
  text-align: center;
  background-color: $yellow;
  position: fixed;
  width: 100%;
  height: 3.5em;
  padding: 1em;
  z-index: 100;
  top: 0;
  transition: top .3s ease;
  &.hidden {
    top: -3em;
    &:hover {
      top: 0;
    }
  }
}
header > nav,
header > h1 {
  display: block;
  margin: 0;
}
header a {
  color: $dark;
  &:hover {
    color: $light-dark;
  }
}

nav a {
  text-decoration: none;
  margin-right: 1em;
}
nav a:last-child {
  margin-right: 0;
}

span.anchor {
  /* correct autoscroll position */
  position: absolute;
  top: -5em;
  main > & {
    top:-1em;
  }
}

.lang-menu {
  // display: none;
  margin-top: 1.5em;
  color: $dark;
  a {
    display: inline-block;
    padding: 0 1.5em;
    margin: 0;
    border-right: $dark 2px solid;
  }
  a:last-of-type {
    border: none;
  }
  a.active {
    color: $grey;
  }
}


#logo {
  position: relative;
}
#logo img {
  position: absolute;
  height: 38px;
  width: auto;
}
.text-center {
  text-align: center;
}
.inline-titles {
  h1, h2, h3 {
    display: inline-block;
    margin-right: 1em;
  }
}
.dark.wrapper, .dark-grey.wrapper, .dark-dark-grey.wrapper {
  background-color: $dark;
  color: $light;
  h1 {
    color: $yellow;
  }
  h2 {
    color: $light;
  }
  h3 {
    color: $light-grey;
  }
  .block, .block h2 {
    color: $dark;
  }
}
.dark-dark-grey.wrapper {
  background: $dark;
  background-image: linear-gradient(to top, $dark-grey, $dark 50%);
}
.dark-grey.wrapper {
  background-color: $dark-grey;
}
.green.wrapper {
  background-color: $green;
  color: $dark;
  h1 {
    color: $dark-green;
  }
}
.yellow.wrapper {
  background-color: $yellow;
  color: $dark;
  h1 {
    color: $dark;
  }
}
.white-green.wrapper {
  background: $light;
  background-image: linear-gradient(to top, $green, $light 50%);
}
.white-yellow.wrapper {
  background: $yellow;
  background-image: linear-gradient(to top, $yellow, $light 50%);
}
.light.wrapper {
  background-color: $light-grey;
}
.light-yellow.wrapper {
  background-color: $light-yellow;
}
.contact.wrapper {
  padding-bottom: 0;
}
.bottom.wrapper {
  padding: 0 0 1em;
  overflow: hidden;
}
.motif::before {
  content: " ";
  position: absolute;
  top: -1.9em;
  display: block;
  width: 100%;
  height: 4em;
  background: transparent url('../img/motif.png') left top repeat-x;
  background-size: 4em;
}
.motif-after::after {
  content: " ";
  position: absolute;
  bottom: -2.1em;
  display: block;
  width: 100%;
  height: 4em;
  background: transparent url('../img/motif.png') left top repeat-x;
  background-size: 4em;
  z-index: 2;
}

section.inner {
  position: relative; // bg image stays under
}
img {
  max-width: 100%;
}
img.full {
  width: 100%;
}
section img, section iframe {
  max-width: 100%;
  margin: 1em 0;
  // border-radius: .25em;
}
table {
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}
th {
  padding: .5em .75em;
  .dark > & {
    background-color: $dark;
    color: $light;
  }
  &.th-titre {
    font-size: 1.2em;
    color: $dark;
    padding: 1em 0 .5em;
    text-align: left;
  }
}
td {
  border-bottom: $dark 1px solid;
  padding: .5em .75em;
}
.rounded {
  border-radius: 50%;
}

main {
  padding: 0;
  position: relative;
}
.block {
  position: relative;
  padding: 2em;
  margin-bottom: 1em;
  background-color: rgba($light, .7);
  border: $light-grey 1px solid;
  border-radius: 2em;
  z-index: 1;
}
.dark .block {
  background-color: $light;
  border-color: $light;
}
.dark.block {
  // background-color: rgba($dark, .7);
  background-image: linear-gradient(to top, $dark, rgba($dark,.7) 50%);
  border: none;
  color: $light;
  h1, h2 {
    color: $yellow;
  }
}
.yellow.block {
  background-color: $yellow;
  border-color: $yellow;
}
.green.block {
  background-color: $green;
  border-color: $green;
}

.hero.logo h1#logo-big {
  position: absolute;
  top:10vh;
  display: block;
  width: 100%;
  text-align: center;
  color: $light;
  display: block;
  transition: all .3s ease;
  img {
    max-height: 60vh;
  }
}
.fond-accueil {
  width: 100%;
  height: 90vh;
  background: transparent url("../img/accueil-fond-600.jpg") bottom center no-repeat;
  background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url('../img/accueil-fond-600.jpg');
  background-size:  100% auto;
  background-position: bottom center;
}

.fond {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}
.fond.bottom {
  top: auto;
  bottom: 0;
  background-position: bottom center;
}
.fond-quoi {
  background-image: url("../img/quoi-fond-600.jpg");
  background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(100,77,55, 0.7) 60%, #c3e350), url('../img/quoi-fond-600.jpg');
}
.fond-activites {
  background-image: url("../img/activites-fond-600.jpg");
  // background-image: linear-gradient(to bottom, $green, rgba($green,.2) 50%, #fff), url('../img/activites-fond-600.jpg');
  background-image: linear-gradient(to bottom, $green, transparent), url('../img/activites-fond-600.jpg');
}

@media (orientation: portrait) {
  .fond-accueil {
    background-image: url("../img/accueil-fondportrait-600.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url('../img/accueil-fondportrait-600.jpg');
  }
  .fond-quoi {
    background-image: url("../img/quoi-fondportrait-600.jpg");
    background-image: linear-gradient(to bottom, rgba(100,77,55, 0) 10%, rgba(100,77,55, 0.7) 60%, #c3e350), url('../img/quoi-fondportrait-600.jpg');
  }
  .fond-activites {
    background-image: url("../img/activites-fondportrait-600.jpg");
    background-image: linear-gradient(to bottom, $green, rgba($green,.2) 50%, #fff), url('../img/activites-fondportrait-600.jpg');
  }
  .fond-accueil, .fond {
    background-size: cover;
  }
}

.green-deg {
  background: $light;
  background-image: linear-gradient(to top, $green, rgba($green,.2) 50%);
}

.hero {
  position: relative;
  height: 50vh;
}
// .hero.full {
//   grid-column: span 8;
// }
.hero.logo {
  height: 100vh;
  // @include vh(height, 100); // mobile support
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  background-color: #a5d200;
}
.green .hero.logo {
  background-color: $green;
}

.hero.logo h2 {
  margin: 0;
}

#balade-illus {
  position: absolute;
  width: 100%;
  height: 310px;
  bottom: 0;
  background: transparent url("../img/bestia-balade.png") center bottom no-repeat;
  background-size: auto 310px;

  h2 {
    position: absolute;
    bottom: 2em;
    width: 100%;
    color: $light;
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-auto-flow: row dense;
  grid-gap: 0;
  a {
    display: block;
    height: 200px;
    grid-column: span 1;
    overflow: hidden;
    margin: .5em;
    img {
      object-fit: cover;
      min-height: 100%;
      min-width: 100%;
      margin: 0;
    }
  }
}
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba($dark, .7);
  padding: 2em;
  &.active {
    display: flex;
  }
  .modal-inner {
    max-width: 1200px;
    height: 90vh;
    overflow: hidden;
    img {
      display: block;
      margin: 0;
      object-fit: cover;
      max-height: 100%;
      max-width: 100%;
    }
  }
}
#easter {
  position: fixed;
  display: block;
}
