/**
 * Based on
 *
 *  - reset.css 2.0 by Eric Meyer
      (public domain)
 *    http://meyerweb.com/eric/tools/css/reset/
 *
 *  - normalize.css 8.0.1 by Nicolas Gallagher and Jonathan Neal
 *    (licensed under MIT)
 *    https://github.com/necolas/normalize.css
 *
 *  - Based on Reboot from Bootstrap 4.2.1
 *    (licensed under MIT)
 *    https://github.com/twbs/bootstrap
 */
/**
 * IE10+ doesn't honor `<meta name="viewport">` in some cases
 */
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
@-ms-viewport {
  width: device-width; }

/**
 * general reset
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/**
 * HTML5 display-role reset for older browsers
 */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, summary {
  display: block; }

/**
 * inherit box model for all elements
 */
*,
*::before,
*::after {
  box-sizing: inherit; }

/**
 * html root rules
 * 1. set border-box for inheritance
 * 2. avoid 300ms click delay on touch devices that support the `touch-action`
 *    CSS property
 * 3. Prevent adjustments of font size after orientation changes in IE, on
 *    Windows Phone and iOS.
 * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
 *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
 * 5. Change the default tap highlight to be completely transparent in iOS.
 */
html {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  touch-action: manipulation;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/**
 * body rules
 * 1. reset line-height to 1
 * 2. set base font-family to sans-serif
 * 3. Set an explicit initial text-align value so that we can later use the
 *    `inherit` value on things like `<th>` elements.
 */
body {
  /* 1 */
  line-height: 1;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  text-align: left; }

/**
 * Lists
 */
ol, ul {
  list-style: none; }

/**
 * Quotes
 */
blockquote, q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

/**
 * Tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  caption-side: bottom; }

/**
 * Table Headers
 * 1. Matches default `<td>` alignment by inheriting from the `<body>`, or the
 *    closest parent with a set `text-align`.
 */
th {
  /* 1 */
  text-align: inherit; }

/**
 * Horizontal Lines
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  overflow: visible; }

/**
 * Preformatted Text
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Don't allow content to break outside
 * 3. We have @viewport set which causes scrollbars to overlap content in IE11
 *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
 *    counteract.
 */
pre,
code,
kbd,
samp {
  /* 1 */
  font-family: monospace, monospace; }

pre {
  /* 2 */
  overflow: auto;
  /* 3 */
  -ms-overflow-style: scrollbar; }

/**
 * Links
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  /* 1 */
  background-color: transparent;
  /* 2 */
  -webkit-text-decoration-skip: objects; }

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Prevent the text-decoration to be skipped.
 */
abbr[title] {
  /* 1 */
  border-bottom: none;
  /* 2 */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* 3 */
  cursor: help;
  /* 4 */
  text-decoration-skip-ink: none; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none; }

/**
 * Hide SVG overflow in IE
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * Remove the default `border-radius` that macOS Chrome adds.
 * Details at https://github.com/twbs/bootstrap/issues/24093
 */
button {
  border-radius: 0; }

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 * Credit: https://github.com/suitcss/base/
 */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/**
 * form element resets
 * 1. Remove the margin in Firefox and Safari
 * 2. inherit font rules
 */
input,
button,
select,
optgroup,
textarea {
  /* 1 */
  margin: 0;
  /* 2 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="reset"],
[type="submit"],
[type="button"] {
  /* 2 */
  -webkit-appearance: button; }

/**
 * Remove the default appearance of temporal inputs to avoid a Mobile Safari
 * bug where setting a custom line-height prevents text from being vertically
 * centered within the input.
 * See https://bugs.webkit.org/show_bug.cgi?id=139848
 * and https://github.com/twbs/bootstrap/issues/11266
 */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
  overflow: auto;
  resize: vertical; }

/**
 * Show the overflow in IE.
 */
button,
input {
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button,
select {
  text-transform: none; }

/**
 * Remove the inheritance of word-wrap in Safari.
 * See https://github.com/twbs/bootstrap/issues/24990
 */
select {
  word-wrap: normal; }

/**
 * Remove inner border and padding from Firefox, but don't restore the outline
 * like Normalize.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * 1. Add the correct box sizing in IE 10-
 * 2. Remove the padding in IE 10-
 */
input[type="radio"],
input[type="checkbox"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
  outline: 0 !important; }

/**
 * Browsers set a default `min-width: min-content` on fieldsets,
 * unlike e.g. `<div>`s, which have `min-width: 0` by default.
 * So we reset that to ensure fieldsets behave more like a standard block element.
 * See https://github.com/twbs/bootstrap/issues/12359
 * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
 */
fieldset {
  min-width: 0; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Set display to block for all browsers
 */
legend {
  /* 1 */
  max-width: 100%;
  white-space: normal;
  /* 2 */
  color: inherit;
  /* 3 */
  display: block; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  /* 1 */
  -webkit-appearance: textfield;
  /* 2 */
  outline-offset: -2px; }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  font: inherit; }

/**
 * Correct element display for output
 */
output {
  display: inline-block; }

/**
 * Add the correct display in IE 10+.
 */
template {
  display: none; }

[hidden] {
  display: none; }

/**
 * adds resets for buttons that are not covered by reset-and-normalize base
 */
[role="button"],
input[type="button"],
input[type="reset"],
input[type="submit"],
button {
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  color: inherit;
  text-align: inherit;
  background: none;
  appearance: button;
  user-select: none; }
  [role="button"]:-moz-focusring,
  input[type="button"]:-moz-focusring,
  input[type="reset"]:-moz-focusring,
  input[type="submit"]:-moz-focusring,
  button:-moz-focusring {
    outline: none; }
  [role="button"]:focus,
  input[type="button"]:focus,
  input[type="reset"]:focus,
  input[type="submit"]:focus,
  button:focus {
    outline: none; }

/**
 * adds resets for links that are not covered by reset-and-normalize base
 */
a {
  color: inherit;
  text-decoration: none; }
  a:-moz-focusring, a:focus {
    outline: none; }

/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;700&display=swap'); */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

html {
  scroll-behavior: smooth; }

body {
  /* font-family: 'Quicksand', sans-serif; */
  /* font-family: 'Abhaya Libre', serif; */
  font-family: 'Libre Baskerville', serif;
  line-height: 1.5;
  background-color: #fff;
  color: #666; }

h1, h2, h3, h4, h5 {
  line-height: 1.3;
  margin-bottom: .7em; }

h1 {
  font-size: 2.2em;
  color: #666;
  margin-top: 0.3em;
  margin-bottom: 1em; }

h2 {
  font-size: 1.5em;
  color: #000; }

h2 + img {
  margin-top: .5em; }

h3 {
  font-size: 1.2em;
  color: #000;
  margin-top: 1em; }

p {
  margin-bottom: 1em; }

p:last-child {
  margin: 0; }

img:last-child {
  margin-bottom: 0; }

ul {
  position: relative;
  margin-top: 1em;
  line-height: 1.1;
  color: #000; }

li {
  margin: 0 0 1em 3em; }
  li span {
    position: absolute;
    left: 0;
    margin-top: -.25em; }

.badge {
  display: inline-block;
  background-color: #000;
  color: #fff;
  padding: .25em .5em;
  border-radius: .5em;
  margin-right: .25em; }

a {
  color: #000; }
  a:hover, a:active {
    color: #dbc500; }
  .green a:hover, a:active {
    color: #326f10; }

button, input[type=button] {
  background-color: #ffe500;
  color: #000;
  border-radius: 2em;
  padding: 1.5em 2em;
  margin: 2em 0 1em;
  cursor: pointer; }
  button:hover, button:active, input[type=button]:hover, input[type=button]:active {
    background-color: #f5dc00; }

input[type=image] {
  margin: 2em;
  width: 100px;
  height: auto; }

form {
  display: flex;
  align-items: center; }
  form button {
    position: relative; }

header {
  text-align: center;
  background-color: #ffe500;
  position: fixed;
  width: 100%;
  height: 3.5em;
  padding: 1em;
  z-index: 100;
  top: 0;
  transition: top .3s ease; }
  header.hidden {
    top: -3em; }
    header.hidden:hover {
      top: 0; }

header > nav,
header > h1 {
  display: block;
  margin: 0; }

header a {
  color: #000; }
  header a:hover {
    color: #666; }

nav a {
  text-decoration: none;
  margin-right: 1em; }

nav a:last-child {
  margin-right: 0; }

span.anchor {
  /* correct autoscroll position */
  position: absolute;
  top: -5em; }
  main > span.anchor {
    top: -1em; }

.lang-menu {
  margin-top: 1.5em;
  color: #000; }
  .lang-menu a {
    display: inline-block;
    padding: 0 1.5em;
    margin: 0;
    border-right: #000 2px solid; }
  .lang-menu a:last-of-type {
    border: none; }
  .lang-menu a.active {
    color: #666; }

#logo {
  position: relative; }

#logo img {
  position: absolute;
  height: 38px;
  width: auto; }

.text-center {
  text-align: center; }

.inline-titles h1, .inline-titles h2, .inline-titles h3 {
  display: inline-block;
  margin-right: 1em; }

.dark.wrapper, .dark-grey.wrapper, .dark-dark-grey.wrapper {
  background-color: #000;
  color: #fff; }
  .dark.wrapper h1, .dark-grey.wrapper h1, .dark-dark-grey.wrapper h1 {
    color: #ffe500; }
  .dark.wrapper h2, .dark-grey.wrapper h2, .dark-dark-grey.wrapper h2 {
    color: #fff; }
  .dark.wrapper h3, .dark-grey.wrapper h3, .dark-dark-grey.wrapper h3 {
    color: #eee; }
  .dark.wrapper .block, .dark.wrapper .block h2, .dark-grey.wrapper .block, .dark-grey.wrapper .block h2, .dark-dark-grey.wrapper .block, .dark-dark-grey.wrapper .block h2 {
    color: #000; }

.dark-dark-grey.wrapper {
  background: #000;
  background-image: linear-gradient(to top, #333, #000 50%); }

.dark-grey.wrapper {
  background-color: #333; }

.green.wrapper {
  background-color: #c3e350;
  color: #000; }
  .green.wrapper h1 {
    color: #326f10; }

.yellow.wrapper {
  background-color: #ffe500;
  color: #000; }
  .yellow.wrapper h1 {
    color: #000; }

.white-green.wrapper {
  background: #fff;
  background-image: linear-gradient(to top, #c3e350, #fff 50%); }

.white-yellow.wrapper {
  background: #ffe500;
  background-image: linear-gradient(to top, #ffe500, #fff 50%); }

.light.wrapper {
  background-color: #eee; }

.light-yellow.wrapper {
  background-color: #fff599; }

.contact.wrapper {
  padding-bottom: 0; }

.bottom.wrapper {
  padding: 0 0 1em;
  overflow: hidden; }

.motif::before {
  content: " ";
  position: absolute;
  top: -1.9em;
  display: block;
  width: 100%;
  height: 4em;
  background: transparent url("../img/motif.png") left top repeat-x;
  background-size: 4em; }

.motif-after::after {
  content: " ";
  position: absolute;
  bottom: -2.1em;
  display: block;
  width: 100%;
  height: 4em;
  background: transparent url("../img/motif.png") left top repeat-x;
  background-size: 4em;
  z-index: 2; }

section.inner {
  position: relative; }

img {
  max-width: 100%; }

img.full {
  width: 100%; }

section img, section iframe {
  max-width: 100%;
  margin: 1em 0; }

table {
  width: 100%;
  text-align: center;
  margin-bottom: 1em; }

th {
  padding: .5em .75em; }
  .dark > th {
    background-color: #000;
    color: #fff; }
  th.th-titre {
    font-size: 1.2em;
    color: #000;
    padding: 1em 0 .5em;
    text-align: left; }

td {
  border-bottom: #000 1px solid;
  padding: .5em .75em; }

.rounded {
  border-radius: 50%; }

main {
  padding: 0;
  position: relative; }

.block {
  position: relative;
  padding: 2em;
  margin-bottom: 1em;
  background-color: rgba(255, 255, 255, 0.7);
  border: #eee 1px solid;
  border-radius: 2em;
  z-index: 1; }

.dark .block {
  background-color: #fff;
  border-color: #fff; }

.dark.block {
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0.7) 50%);
  border: none;
  color: #fff; }
  .dark.block h1, .dark.block h2 {
    color: #ffe500; }

.yellow.block {
  background-color: #ffe500;
  border-color: #ffe500; }

.green.block {
  background-color: #c3e350;
  border-color: #c3e350; }

.hero.logo h1#logo-big {
  position: absolute;
  top: 10vh;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  display: block;
  transition: all .3s ease; }
  .hero.logo h1#logo-big img {
    max-height: 60vh; }

.fond-accueil {
  width: 100%;
  height: 90vh;
  background: transparent url("../img/accueil-fond-600.jpg") bottom center no-repeat;
  background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url("../img/accueil-fond-600.jpg");
  background-size: 100% auto;
  background-position: bottom center; }

.fond {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat; }

.fond.bottom {
  top: auto;
  bottom: 0;
  background-position: bottom center; }

.fond-quoi {
  background-image: url("../img/quoi-fond-600.jpg");
  background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(100, 77, 55, 0.7) 60%, #c3e350), url("../img/quoi-fond-600.jpg"); }

.fond-activites {
  background-image: url("../img/activites-fond-600.jpg");
  background-image: linear-gradient(to bottom, #c3e350, transparent), url("../img/activites-fond-600.jpg"); }

@media (orientation: portrait) {
  .fond-accueil {
    background-image: url("../img/accueil-fondportrait-600.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url("../img/accueil-fondportrait-600.jpg"); }
  .fond-quoi {
    background-image: url("../img/quoi-fondportrait-600.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(100, 77, 55, 0.7) 60%, #c3e350), url("../img/quoi-fondportrait-600.jpg"); }
  .fond-activites {
    background-image: url("../img/activites-fondportrait-600.jpg");
    background-image: linear-gradient(to bottom, #c3e350, rgba(195, 227, 80, 0.2) 50%, #fff), url("../img/activites-fondportrait-600.jpg"); }
  .fond-accueil, .fond {
    background-size: cover; } }

.green-deg {
  background: #fff;
  background-image: linear-gradient(to top, #c3e350, rgba(195, 227, 80, 0.2) 50%); }

.hero {
  position: relative;
  height: 50vh; }

.hero.logo {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  background-color: #a5d200; }

.green .hero.logo {
  background-color: #c3e350; }

.hero.logo h2 {
  margin: 0; }

#balade-illus {
  position: absolute;
  width: 100%;
  height: 310px;
  bottom: 0;
  background: transparent url("../img/bestia-balade.png") center bottom no-repeat;
  background-size: auto 310px; }
  #balade-illus h2 {
    position: absolute;
    bottom: 2em;
    width: 100%;
    color: #fff; }

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-auto-flow: row dense;
  grid-gap: 0; }
  .gallery a {
    display: block;
    height: 200px;
    grid-column: span 1;
    overflow: hidden;
    margin: .5em; }
    .gallery a img {
      object-fit: cover;
      min-height: 100%;
      min-width: 100%;
      margin: 0; }

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2em; }
  .modal.active {
    display: flex; }
  .modal .modal-inner {
    max-width: 1200px;
    height: 90vh;
    overflow: hidden; }
    .modal .modal-inner img {
      display: block;
      margin: 0;
      object-fit: cover;
      max-height: 100%;
      max-width: 100%; }

#easter {
  position: fixed;
  display: block; }

.wrapper {
  position: relative;
  padding: 0 0 1em; }
  .wrapper.space {
    padding-top: 50vh; }

section.inner {
  max-width: 100%;
  margin: 0;
  padding: 1em; }

@media (max-width: 479px) {
  header {
    position: fixed;
    left: -100%;
    height: 100%;
    width: 100%;
    padding: 2em;
    transition: left .5s ease; }
    header nav {
      overflow-y: auto; }
      header nav a {
        display: block;
        padding: 1em 1em .25em;
        margin: 0;
        font-size: 1.2em; }
    header.active {
      left: 0; }
  #toggle-menu {
    position: fixed;
    z-index: 200;
    top: 1em;
    right: 1em;
    display: block;
    width: 3em;
    height: 3em;
    background: transparent url("../img/hamburger.svg") top left no-repeat;
    background-size: cover; }
  span.anchor {
    /* correct autoscroll position */
    position: absolute;
    top: -1em; }
    main > span.anchor {
      top: 0em; }
  h1 {
    line-height: 1.3;
    margin-bottom: .5em; }
  .span2, .span3, .span4, .span5, .span6, .span7, .span8, .span9, .full {
    margin: 1em 0; }
  .block {
    border-radius: 1em;
    padding: 1.5em 1em; }
    .block p {
      margin-bottom: .75em; }
  #balade-illus {
    bottom: -7vh; }
    #balade-illus h2 {
      bottom: 3em; } }

@media (max-height: 479px) {
  .fond-accueil {
    position: absolute;
    top: 0; }
  .hero.logo {
    height: auto; }
    .hero.logo h1 {
      position: relative;
      top: 0 !important;
      margin-top: 0;
      height: 30vh; }
      .hero.logo h1 img {
        width: 60vh; }
  #balade-illus {
    position: relative;
    bottom: auto; }
    #balade-illus h2 {
      bottom: 4em; } }

@media (min-width: 480px) and (max-width: 1023px) {
  header {
    position: fixed;
    left: -100%;
    height: 100%;
    width: 100%;
    padding: 2em;
    transition: left .5s ease; }
    header nav {
      overflow-y: auto; }
      header nav a {
        display: block;
        padding: 1em 1em .25em;
        margin: 0;
        font-size: 1.2em; }
    header.active {
      left: 0; }
  #toggle-menu {
    position: fixed;
    z-index: 200;
    top: 1em;
    right: 1em;
    display: block;
    width: 3em;
    height: 3em;
    background: transparent url("../img/hamburger.svg") top left no-repeat;
    background-size: cover; }
  .wrapper {
    position: relative;
    padding: 2em 0 4em; }
    .wrapper.space {
      padding-top: 50vh; }
  section.inner {
    max-width: 100%;
    margin: 0;
    padding: 2em;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row dense;
    grid-gap: 2em; }
    section.inner > * {
      grid-column: span 3; }
    section.inner .span3 {
      grid-column: span 2; }
    .contact section.inner .span3 {
      grid-column: span 3; }
    section.inner .full {
      grid-column: span 6; }
  .dark.wrapper section.inner {
    align-items: center;
    padding-top: 4em; }
  #balade-illus {
    bottom: -5vh; }
    #balade-illus h2 {
      bottom: 2em; }
  .fond-accueil {
    background-image: url("../img/accueil-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url("../img/accueil-fond-1600.jpg"); }
  .fond-quoi {
    background-image: url("../img/quoi-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(100, 77, 55, 0.7) 60%, #c3e350), url("../img/quoi-fond-1600.jpg"); }
  .fond-activites {
    background-image: url("../img/activites-fond-1600.jpg");
    background-image: linear-gradient(to bottom, #c3e350, rgba(195, 227, 80, 0.2) 50%, #fff), url("../img/activites-fond-1600.jpg"); }
  .fond-accueil, .fond {
    background-size: cover; }
  .gallery {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-height: 700px) {
  #balade-illus {
    bottom: -10vh; }
    #balade-illus h2 {
      bottom: 3em; }
  .fond-accueil {
    background-image: url("../img/accueil-fond-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url("../img/accueil-fond-1024.jpg"); }
  .fond-quoi {
    background-image: url("../img/quoi-fond-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(100, 77, 55, 0.7) 60%, #c3e350), url("../img/quoi-fond-1024.jpg"); }
  .fond-activites {
    background-image: url("../img/activites-fond-1024.jpg");
    background-image: linear-gradient(to bottom, #c3e350, transparent), url("../img/activites-fond-1024.jpg"); }
  .fond-accueil, .fond {
    background-size: cover; } }

@media (orientation: portrait) {
  .fond-accueil {
    background-image: url("../img/accueil-fondportrait-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url("../img/accueil-fondportrait-1024.jpg"); }
  .fond-quoi {
    background-image: url("../img/quoi-fondportrait-1024.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(100, 77, 55, 0.7) 60%, #c3e350), url("../img/quoi-fondportrait-1024.jpg"); }
  .fond-activites {
    background-image: url("../img/activites-fondportrait-1024.jpg");
    background-image: linear-gradient(to bottom, #c3e350, rgba(195, 227, 80, 0.2) 50%, #fff), url("../img/activites-fondportrait-1024.jpg"); }
  .fond-accueil, .fond {
    background-size: cover; } }

@media (orientation: portrait) and (max-height: 800px) {
  .fond-accueil {
    background-image: url("../img/accueil-fondportrait-800.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url("../img/accueil-fondportrait-800.jpg"); }
  .fond-quoi {
    background-image: url("../img/quoi-fondportrait-800.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(100, 77, 55, 0.7) 60%, #c3e350), url("../img/quoi-fondportrait-800.jpg"); }
  .fond-activites {
    background-image: url("../img/activites-fondportrait-800.jpg");
    background-image: linear-gradient(to bottom, #c3e350, rgba(195, 227, 80, 0.2) 50%, #fff), url("../img/activites-fondportrait-800.jpg"); }
  .fond-accueil, .fond {
    background-size: cover; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  header {
    text-align: left;
    padding: 1em 1.5em; } }

@media (min-width: 1024px) {
  .wrapper {
    position: relative;
    padding: 3em 0 5em; }
    .wrapper.space {
      padding-top: 50vh; }
    .wrapper.space-bottom {
      padding-bottom: 50vh; }
  .bottom.wrapper {
    padding: 0; }
  section.inner {
    max-width: 980px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row dense;
    grid-gap: 2em; }
  .dark.wrapper section.inner {
    align-items: center;
    padding-top: 4em; }
  .col5-10 {
    grid-column: 5/10; }
  .col-center {
    grid-column: 3/8; }
  .lang-menu {
    margin: 0;
    position: absolute;
    top: 1em;
    right: 1em; }
    .lang-menu a {
      padding: 0 .75em; }
  #bienfaits, .bienfaits {
    margin-top: 16em; }
  figure {
    grid-column: span 2;
    grid-row: span 1; }
  .span2 {
    grid-column: span 2; }
  .span3 {
    grid-column: span 3; }
  .span4 {
    grid-column: span 4; }
  .span5 {
    grid-column: span 5; }
  .span6 {
    grid-column: span 6; }
  .span7 {
    grid-column: span 7; }
  .span8 {
    grid-column: span 8; }
  .span9, .full {
    grid-column: span 9; }
  .grid-start {
    align-self: start; }
  .grid-end {
    align-self: end; }
  .left {
    grid-column: 2/6; }
  .right {
    grid-column: 6/8; }
  header {
    display: block; }
  .fond-accueil {
    background-image: url("../img/accueil-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(255, 221, 85, 0.46) 60%, #a5d200), url("../img/accueil-fond-1600.jpg"); }
  .fond-quoi {
    background-image: url("../img/quoi-fond-1600.jpg");
    background-image: linear-gradient(to bottom, rgba(100, 77, 55, 0) 10%, rgba(100, 77, 55, 0.7) 60%, #c3e350), url("../img/quoi-fond-1600.jpg"); }
  .fond-activites {
    background-image: url("../img/activites-fond-1600.jpg");
    background-image: linear-gradient(to bottom, #c3e350, transparent), url("../img/activites-fond-1600.jpg"); }
  #logo-small {
    position: fixed;
    z-index: 1000;
    bottom: -210px;
    left: 50%;
    display: block;
    width: 200px;
    height: 200px;
    max-height: 25vh;
    margin: 0;
    margin-left: -100px;
    background: transparent url("../img/bestia-logo.svg") bottom center no-repeat;
    background-size: contain;
    transition: bottom .5s ease; }
    #logo-small.active {
      bottom: -20px; }
    #logo-small span {
      display: none; }
  .gallery {
    grid-template-columns: repeat(3, 1fr); } }

@media (min-width: 1400px) {
  section.inner {
    max-width: 1200px; }
  .gallery {
    grid-template-columns: repeat(4, 1fr); } }
