// variables.scss

$dark: #000;
$light-dark: #666;
$light: #fff;
$light-grey: #eee;
$grey: #666;
$dark-grey: #333;

$yellow: #ffe500;
$light-yellow: #fff599;;
$green: #c3e350;
$dark-green: #326f10;
$brown: #97550e;
